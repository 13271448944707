/* reducers.js */
import * as R from "ramda";

import AppInitialState from "./../../../app-state/app-initial-state";

const Reducer = function(stats = AppInitialState.stats, action = {}) {
	switch (action.type) {
		case "LOAD_STATS":
			const matches = R.pathOr([], ["agent", "statistics"], action);
			const agent = R.pathOr([], ["agent", "staker"], action);

			const histogram = {
				wins: {},
				losses: {},
				predictions: {},
				wagers: {},
			};
			const streaks_w = [];
			const streaks_l = [];

			let gain = 0;
			let loss = 0;
			let w_total = 0;
			let wins = 0;
			let high_streak_w = 0;
			let l_total = 0;
			let losses = 0;
			let high_streak_l = 0;

			matches.forEach((match) => {
				const amount = +R.pathOr(0, ["amount"], match);
				const prediction = +R.pathOr(null, ["prediction"], match);
				const winAmount = +R.pathOr(undefined, ["winAmount"], match);

				if (!histogram.predictions[prediction]) {
					histogram.predictions[prediction] = true;
				}

				if (!histogram.wagers[amount]) {
					histogram.wagers[amount] = true;
				}

				if (typeof winAmount !== "undefined" && prediction === 50) {
					if (winAmount === 0) {
						if (wins > 1) {
							streaks_w.push(wins);

							if (histogram.wins["hist" + wins]) {
								histogram.wins["hist" + wins]++;
							} else {
								histogram.wins["hist" + wins] = 1;
							}
						}

						loss += amount;
						wins = 0;
						l_total++;
						losses++;
						high_streak_l = losses > high_streak_l ? losses : high_streak_l;
					} else {
						if (losses > 1) {
							streaks_l.push(losses);

							if (histogram.losses["hist" + losses]) {
								histogram.losses["hist" + losses]++;
							} else {
								histogram.losses["hist" + losses] = 1;
							}
						}

						gain += winAmount - amount;
						losses = 0;
						w_total++;
						wins++;
						high_streak_w = wins > high_streak_w ? wins : high_streak_w;
					}
				}
			});

			const _stat = {
				[agent]: {
					histogram,
					streaks_w,
					streaks_l,
					gain,
					loss,
					w_total,
					high_streak_w,
					l_total,
					high_streak_l,
				},
			};

			return { ...stats, ..._stat };

		default:
			return stats;
	}
};

export default Reducer;
