/* App.js */

/** External Module Dependencies **/
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import "../styles/style-bootstrap.css";
import "../styles/style-index.css";
import "../styles/style-app.css";

/** Global Module Dependencies **/
import routes from "routes";

const App = () => {
	const context = useWeb3React();

	return (
		<div className="app">
			<div className="main-content">
				<Routes>
					{routes.map((route, idx) => {
						return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} element={<route.component context={context} />} />  : null;
					})}
				</Routes>
			</div>
		</div>
	);
};

export default App;
