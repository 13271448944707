import styled from "styled-components";

const StyledIcon = styled.i`
  width: 24px;
  margin-right: 8px;
  padding-right: 6px;
  font-size: 20px;
  text-align: center;
`;

export { StyledIcon };