/* reducers.js */
import * as R from "ramda";

import AppInitialState from "./../../../app-state/app-initial-state";

const Reducer = function(events = AppInitialState.events, action = {}) {
	switch (action.type) {
		case "MESSAGE_SYNC": {
			const messages = R.pathOr([], ["messages"], events);
			messages.push(action.message);

			const _event = {};
			_event.messages = messages;

			return { ...events, ..._event };
		}

		default:
			return events;
	}
};

export default Reducer;
