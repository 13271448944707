/* reducer.js */

/** External Module Dependencies **/
import { combineReducers } from "redux";

/** Internal Module Dependencies **/
import events from "./../components/events/reducer/reducer";
import product from "./../components/product/reducer/reducer";

export default combineReducers({
	events,
	product,
});
