/* app-initial-state.js */

const AppInitialState = {
	app: {},
	home: {},
	stats: {},
	events: {},
};

export default AppInitialState;
